const tagStyle = {
  fontSize: 12,
  fontWeight: 500,
  padding: "2px 8px",
  borderRadius: 20,
  margin: "8px 8px 0px 0px"
}

export {
	tagStyle
}